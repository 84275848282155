export default class Sum {
  constructor() {
    this.name = null;
    this.status = null;
    this.is_taken = null;
    this.description = null;
    this.type = null;
    this.minimum = null;
    this.maximum = null;
    this.default = null;
    this.step = null;
    this.basic_rate = null;
    this.basic_percent = null;
    this.thresholds = [];
    this.sum_tax_settings = {};
  }
}
