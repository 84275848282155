<template>
  <div class="pa-md-10 pa-lg-10 ">
    <div class="text-center">
      <h2 v-if="crud_state == '3'">Add Sum</h2>
      <h2 v-else-if="crud_state == '2'">Edit Sum</h2>
    </div>
    <div class="space-30"></div>
    <v-form v-model="formValid" ref="formValid">
      <v-card class="pa-10 ">
        <v-row>
          <v-col cols="12">
            <p>Sum Name</p>
            <v-text-field
              outlined
              v-model="item.name"
              :rules="[(v) => !!v || `Name is Required`]"
              dense
              label="Enter Sum Name"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" lg="4">
            <div class="ml-5">
              <p>Taken</p>
              <v-switch v-model="item.taken" label="yes"></v-switch>
            </div>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>Sum Description</p>
            <v-textarea
              label="Enter Sum Description"
              outlined
              v-model="item.description"
              dense
              rows="2"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="3">
            <p>Referral Limit</p>
          </v-col>
          <v-col cols="12" lg="9">
            <v-text-field
              :label="`Enter Referral Limit`"
              v-model="item.referral_limit"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="3">
            <p>Winbeat Tag Name</p>
          </v-col>
          <v-col cols="12" lg="9">
            <v-text-field
              :label="`Enter Winbeat Tag Name`"
              v-model="item.wb_tag_name"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="3">
            <p>Sum Type</p>
          </v-col>
          <v-col cols="12" lg="9">
            <v-select
              :items="sumTypes"
              label="Select Sum Type"
              v-model="item.type"
              :rules="[(v) => !!v || `Sum Type is Required`]"
              item-text="name"
              @change="sumTypeChanged"
              item-value="value"
              outlined
              dense
            >
            </v-select>
          </v-col>
        </v-row>
        <p><b>Sum Parameters</b></p>
        <div class="space-20"></div>
        <v-row
          class="mt-n5"
          v-for="(param, index) in sumParameters"
          :key="index"
        >
          <v-col cols="12" lg="3">
            <p>{{ param.name }}</p>
          </v-col>
          <v-col cols="12" lg="9">
            <v-text-field
              :label="`Enter ${param.name} value`"
              type="number"
              :rules="[(v) => !!v || `${param.name} Value is Required`]"
              v-model="item[param.value]"
              outlined
              clearable
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <template v-if="item.type == 'basic'">
          <p><b>Sum Rates</b></p>
          <v-row>
            <v-col cols="12" lg="3">
              <p>Basic Rate</p>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field
                prefix="$"
                type="number"
                v-model="item.basic_rate"
                :disabled="item.basic_percent ? true : false"
                outlined
                clearable
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field
                :label="`%`"
                type="number"
                clearable
                :disabled="item.basic_rate ? true : false"
                v-model="item.basic_percent"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <template
          v-else-if="item.type == 'complex' || item.type == 'complex_fixed'"
        >
          <p><b>Sum Rates</b></p>
          <div class="space-20"></div>
          <v-row
            class="mt-n5"
            v-for="(threshold, index) of item.thresholds"
            :key="threshold.value"
          >
            <v-col cols="12" lg="3">
              <p>
                {{ threshold.name ? threshold.name : `Threshold ${index + 1}` }}
              </p>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field
                label="From"
                type="number"
                clearable
                v-model="threshold.thrsh_from"
                @change="thresholdValidate(threshold)"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field
                label="To"
                type="number"
                clearable
                @change="thresholdValidate(threshold)"
                v-model="threshold.thrsh_to"
                outlined
                dense
                :disabled="item.type == 'complex_fixed'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field
                prefix="$"
                type="number"
                clearable
                v-model="threshold.rate"
                @change="thresholdValidate(threshold)"
                :disabled="threshold.amount ? true : false"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field
                label="%"
                type="number"
                clearable
                :disabled="threshold.rate ? true : false"
                @change="thresholdValidate(threshold)"
                v-model="threshold.amount"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <v-row
          v-if="
            id && item.type == 'complex_fixed' && selectedSum.thresholds[0].id
          "
        >
          <v-col cols="12" lg="3">
            <p>Min ACCA value</p>
          </v-col>
          <v-col cols="12" lg="9">
            <v-radio-group
              v-model="accaMinThresholdId"
              @change="addAccaMinThresholdId()"
              row
              style="padding-top: 0; margin-top:0"
            >
              <v-radio
                v-for="(option, index) in item.thresholds"
                :key="option.id"
                :label="`Threshold ${index + 1}`"
                :value="option.id"
                :disabled="!option.thrsh_from"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <p class="mt-4"><b>Sum Tax Settings</b></p>
        <div class="space-20"></div>
        <v-row>
          <v-col
            class="mt-n5"
            v-for="(tax, index) in sum_tax_settings"
            :key="index"
            cols="12"
            lg="4"
          >
            <p>{{ tax.name }}</p>
            <v-switch
              v-model="item.sum_tax_settings[tax.value]"
              label="yes"
            ></v-switch>
          </v-col>
        </v-row>

        <div>
          <v-row class="justify-end">
            <v-btn color="success" @click="validate">
              <v-icon small left>mdi-check</v-icon>
              Save
            </v-btn>
            <router-link class="no-link" to="/master-data/sum/list">
              <v-btn text>
                Cancel
              </v-btn>
            </router-link>
          </v-row>
        </div>
      </v-card>
    </v-form>
    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>
<script>
import Sum from "@/models/master-data/sum";
import Snackbar from "@/components/common/Snackbar";
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import { bus } from "@/main.js";

export default {
  name: "SumEdit",
  data() {
    return {
      item: { sum_tax_settings: {} },
      sumTypes: [
        { name: "Basic", value: "basic" },
        { name: "Complex", value: "complex" },
        { name: "Complex Fixed", value: "complex_fixed" },
      ],
      sumParameters: [
        { name: "Minimum", value: "minimum" },
        { name: "Maximum", value: "maximum" },
        { name: "Default", value: "default" },
        { name: "Step", value: "step" },
      ],
      sum_tax_settings: [
        { name: "Has Cyclone Levy", value: "has_cyclone_levy" },
        { name: "Has Terror Levy", value: "has_terror_levy" },
        { name: "Has ESL FSL Levy", value: "has_esl_fsl_levy" },
        { name: "Has Common Stamp Duty", value: "has_comm_stamp_duty" },
        { name: "Has PA Stamp Duty", value: "has_pa_stamp_duty" },
        { name: "Has GST Tax", value: "has_gst_tax" },
      ],
      snackbar: {},
      formValid: false,
      loading: false,
      accaMinThresholdId: "",
    };
  },
  mounted() {
    this.accaMinThresholdId = this.item.thresholds.find(
      (item) => item.is_acca_min
    )?.id;
  },
  watch: {
    "item.type": {
      handler(val) {
        if (val === "complex" || val === "complex_fixed") {
          this.prepareThreshold();
        }
      },
      deep: true,
    },
  },
  methods: {
    prepareThreshold() {
      this.item.thresholds = Array(5)
        .fill(null)
        .map((item, index) => {
          return {
            name: `Threshold ${index + 1}`,
            value: `threshold${index + 1}`,
            from: null,
            to: null,
            rate: null,
            percent: null,
            isError: false,
          };
        });
    },
    sumTypeChanged() {
      this.item.basic_percent = null;
      this.item.basic_rate = null;
      this.item.thresholds = [];
    },
    addAccaMinThresholdId() {
      this.item.thresholds.forEach((item) => {
        item.is_acca_min = item.id === this.accaMinThresholdId;
      });
    },
    validate() {
      // if (!this.item.taken) {
      //   this.saveSum();
      // } else {
      this.$refs.formValid.validate();
      if (this.formValid) {
        let rateValidated = this.checkRatesValidation();
        if (rateValidated) {
          this.saveSum();
        }
      }
      // }
    },
    thresholdValidate(threshold) {
      let thresholdIndex = this.item.thresholds.findIndex(
        (thres) => thres.value == threshold.value
      );
      let selectedThreshold = this.item.thresholds[thresholdIndex];

      if (selectedThreshold) {
        if (
          selectedThreshold.thrsh_from === null ||
          (this.item.type === "complex" && selectedThreshold.thrsh_to === null)
        ) {
          selectedThreshold.isError = true;
          return;
        } else if (
          selectedThreshold.rate === null &&
          selectedThreshold.amount === null
        ) {
          selectedThreshold.isError = true;
          return;
        } else {
          selectedThreshold.isError = false;
        }
      }
    },
    async get_item(id) {
      if (!id) {
        return null;
      }
      this.loading = true;
      let service = new BaseService("sums");
      let res = await service.get_one(id);
      this.loading = false;
      return res.data;
    },
    async saveSum() {
      this.loading = true;
      let service = new BaseService("sums");
      let res = null;
      if (this.crud_state == crud_state.NEW) {
        res = await service.create(this.item);
      } else {
        res = await service.update(this.item);
      }
      this.loading = false;

      this.$router.push(`/master-data/sum/list`);

      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        bus.$emit("edit_save", {
          msg: "Saved successfully",
          data: res.data.results,
        });

        setTimeout(() => {
          this.$router.go();
        }, 1000);
      } else {
        bus.$emit("edit_error", { msg: "An error occurred, could not save" });
      }
    },
    checkRatesValidation() {
      // if (!this.item.taken) return true;

      if (this.item.type == "basic") {
        if (
          (this.item.basic_rate === null || this.item.basic_rate === "") &&
          (this.item.basic_percent === null || this.item.basic_percent === "")
        ) {
          this.snackbar = {
            show: true,
            text: `Please add the rate or percentage of the sum`,
            color: `red`,
          };

          this.resetSnackbar();
          return;
        }
      } else if (
        this.item.type == "complex" ||
        this.item.type == "complex_fixed"
      ) {
        let thresholdFilters;
        if (this.item.type == "complex") {
          thresholdFilters = this.item.thresholds.filter((threshold) => {
            return (
              threshold.thrsh_from !== null ||
              threshold.thrsh_to !== null ||
              threshold.rate ||
              threshold.amount
            );
          });
        } else {
          thresholdFilters = this.item.thresholds.filter((threshold) => {
            return (
              threshold.thrsh_from !== null ||
              threshold.rate ||
              threshold.amount
            );
          });
        }

        // When no threshold is entered
        if (thresholdFilters.length < 1) {
          this.snackbar = {
            show: true,
            text: `Please select atleast one threshold`,
            color: `red`,
          };
          this.resetSnackbar();
          return;
        } else {
          //When Individual Required Fields are not Filled
          let allThresholdValidated = this.item.thresholds.filter((thres) => {
            return thres.isError == true;
          });
          if (allThresholdValidated.length > 0) {
            this.snackbar = {
              show: true,
              text: `Please Enter All the Fields in ${allThresholdValidated[0].name}`,
              color: `red`,
            };
            this.resetSnackbar();
            return;
          }
        }
      }

      let cleanedThresholds = this.item.thresholds.filter((threshold) => {
        if (this.item.type == "complex")
          return threshold.thrsh_from !== null && threshold.thrsh_to !== null;
        else if (this.item.type == "complex_fixed")
          return threshold.thrsh_from !== null;
      });
      this.item.thresholds = cleanedThresholds;
      return true;
    },
    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
  },
  computed: {
    crud_state() {
      return this.$route.query.crud_state;
    },
  },
  props: {
    id: String,
  },
  async created() {
    if (this.id) {
      this.item = await this.get_item(this.id);
    } else {
      this.item = new Sum();
      this.item.status = true;
    }
  },
  components: {
    Snackbar,
  },
};
</script>
